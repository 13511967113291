import styled from 'styled-components'

export const TextImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 20vw;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 1200px) {
    margin: 2rem 10vw;
  }

  @media only screen and (max-width: 800px) {
    margin: 2rem .5rem;
  }
`

export const TextContainer = styled.div`
  flex-grow: 1;
  max-width: 60%;

  @media only screen and (max-width: 800px) {
    width: 95vw;
    max-width: unset;
    flex-grow: 0;
  }
`

export const TextWrapper = styled.div`
  max-width: 50rem;
  font-size: 1.1em;
`

export const ImageContainer = styled.div`
  width: min(20vw, 250px);
  height: min(20vw, 250px);
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  filter: drop-shadow(0 2px 5px rgba(0,0,0,.3));

  @media only screen and (max-width: 800px) {
    width: 35vw;
    height: 35vw;
    margin: 1.5rem auto 0 auto;
  }
  
  @media only screen and (max-width: 550px) {
    width: 60vw;
    height: 60vw;
  }
`

export const Image = styled.img`
  width: unset;
  height: 100%;
  max-width: 100%;
`