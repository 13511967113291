import { Component, createRef } from 'react'
import { Link } from'react-router-dom'

import MenuDropdown from './menu-dropdown'

import { NavLogo, HeaderStyle, HeaderTextBox, HeaderText, NavStyle, NavToggleLabel, NavToggleInput, HeaderItems, LoginButtonStyle, LoginButtonText, NavMenu, NavMenuContainer, NavMenuColumn, NavMenuSubject, NavMenuText } from './navbar.styled'

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      navStyle: {},
      headerTextStyle: {},
      headerStyle: {},
      showAccountOptions: false
    }

    this.oldScroll = 0
    this.toggleRef = createRef()
    this.scrollEventTimeout = undefined
    this.toggleShowMenu = () => {}
  }

  componentDidMount = () => {
    window.addEventListener("scroll", () => {
      if (this.toggleRef.current.checked) return;
      // if (window.innerWidth <= 800) {
        if (window.scrollY > 64 && this.oldScroll < 64) {
          this.setState({
            headerStyle: { backgroundColor: "rgba(0,0,0,.87)" }
          })
        } else if (window.scrollY < 64 && this.oldScroll > 64) {
          this.setState({ headerStyle: {} })
        }
      // }

      this.oldScroll = window.scrollY
    })
  }

  toggleShow = e => {
    if (e?.target?.checked || this.toggleRef.current.checked) {
      this.setState({
        navStyle: {
          transform: "scale(1,1)",
          // borderBottom: "3px solid var(--primary-color)",
          paddingBottom: "1rem"
        },
        headerTextStyle: {
          opacity: "1",
          transition: "opacity 250ms ease-in-out 250ms"
        }
      })
      if (window.scrollY <= 50) this.setState({ headerStyle: { backgroundColor: "rgba(0,0,0,.87)" } })
    } else {
      this.setState({
        navStyle: {},
        headerTextStyle: {},
        headerStyle: {}
      })
      if (window.scrollY > 50) this.setState({ headerStyle: { backgroundColor: "rgba(0,0,0,.87)" } })
    }
  }

  hideDropdown = () => {
    this.toggleRef.current.checked = false
    this.toggleShow()
    this.toggleShowMenu(true, false)
  }

  render() {
    const { headerTextStyle, headerStyle, navStyle } = this.state

    return (
      <HeaderStyle style={headerStyle}>
        <span/>
        <Link to="/" style={{ zIndex: 999 }} onClick={this.hideDropdown}>
          <NavLogo src="/logo-wht.svg" alt="Web-Fuse Logo" width="48" height="48" />
        </Link>

        <NavStyle style={navStyle} >
          <NavMenu>
            <MenuDropdown title="hosting" setToggleFunc={f => this.toggleShowMenu = f} style={headerTextStyle}>
              <NavMenuSubject> Onze services </NavMenuSubject>

              <NavMenuContainer>
                <NavMenuColumn>
                  <Link to="/hosting" onClick={this.hideDropdown}>
                    <NavMenuText> Webhosting </NavMenuText>
                  </Link>
                  <Link to="/hosting/forward" onClick={this.hideDropdown}>
                    <NavMenuText> Domeinnaam doorsturen </NavMenuText>
                  </Link>
                  <Link to="/wordpress" onClick={this.hideDropdown}>
                    <NavMenuText> Wordpress hosting </NavMenuText>
                  </Link>
                </NavMenuColumn>

                <NavMenuColumn>
                  <Link to="/mail" onClick={this.hideDropdown}>
                    <NavMenuText> E-mail only </NavMenuText>
                  </Link>
                  <Link to="/mail/forward" onClick={this.hideDropdown}>
                    <NavMenuText> Mail doorsturen </NavMenuText>
                  </Link>
                  <Link to="/mail/service" onClick={this.hideDropdown}>
                    <NavMenuText> Mail Service </NavMenuText>
                  </Link>

                </NavMenuColumn>
              </NavMenuContainer>

              <Link to="/" onClick={this.hideDropdown}>
                <NavMenuText> Home </NavMenuText>
              </Link>
            </MenuDropdown>
          </NavMenu>

          <HeaderItems>
            <Link to="/contact" onClick={this.hideDropdown}>
              <HeaderTextBox>
                <HeaderText style={headerTextStyle}>
                  Contact
                </HeaderText>
              </HeaderTextBox>
            </Link>

            <a href="https://cockpit.web-fuse.nl">
              <LoginButtonStyle>
                <LoginButtonText style={headerTextStyle}>
                  Login
                </LoginButtonText>
              </LoginButtonStyle>
            </a>
          </HeaderItems>
        </NavStyle>

        <NavToggleLabel htmlFor="nav-toggle">
          <NavToggleInput ref={this.toggleRef} onChange={this.toggleShow} type="checkbox" id="nav-toggle" aria-label="show navigation bar" />
        </NavToggleLabel>

      </HeaderStyle>
    )
  }
}

export default Navbar;