import { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router'
import { MainContent } from '../styled/container'
import Hosting from './hosting'

import Navbar from './navbar'
import Footer from './reusables/footer'
import Wordpress from './wordpress'
import LandingPage from './landingpage'
import Contact from './contact'
import Start from './start'

class Index extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <Fragment>
      <Route path="/" component={Navbar} />
      <MainContent>
      {/* <div style={{ position: "fixed", top: "0", height: "100vw", left: "10%", width: "2px", backgroundColor: "red" }}/>
      <div style={{ position: "fixed", top: "0", height: "100vw", left: "20%", width: "2px", backgroundColor: "green" }}/>
      <div style={{ position: "fixed", top: "0", height: "100vw", left: "80%", width: "2px", backgroundColor: "green" }}/>
      <div style={{ position: "fixed", top: "0", height: "100vw", left: "90%", width: "2px", backgroundColor: "red" }}/> */}
        <Switch>
          <Route path="/wordpress" component={Wordpress} />
          <Route path="/hosting" component={Hosting} />
          <Route path="/contact" component={Contact}/>
          <Route path="/start" component={Start}/>
          <Route path="/" component={LandingPage} />
        </Switch>
        <Route path="/" component={Footer} />
      </MainContent>
    </Fragment>
  )
}

export default Index