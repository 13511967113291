import { Component, Fragment } from 'react'
import { PageTitle, TextGeneral } from '../../../styled/text'
import TextImage from '../../reusables/textImage'

import FixWP from "../../../assets/img/fix-wp.webp"

class WhyWordpress extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <Fragment>
      <PageTitle> Waarom Wordpress </PageTitle>
      <TextImage
        image={FixWP}
        altImage="Wordpress"
        title="Waarom Wordpress"
      >
        <TextGeneral>
          Wordpress wordt door bijna de helft van alle webpagina's gebruikt om websites mee te maken, en dit heeft een goede reden.
          Wordpress is heel gemakkelijk in gebruik te nemen en er is een gigantisch aantal aanbod aan plug-ins en thema's.
          Dankzij de actieve gemeenschap die werkt aan Wordpress is er voor ieder probleem wel een oplossing en kan jij aan de slag met jouw ideale website.
        </TextGeneral>
      </TextImage>
      <TextImage
        image={FixWP}
        altImage="Wordpress"
        title="Waarom Wordpress"
      >
        <TextGeneral>
          Wordpress wordt door bijna de helft van alle webpagina's gebruikt om websites mee te maken, en dit heeft een goede reden.
          Wordpress is heel gemakkelijk in gebruik te nemen en er is een gigantisch aantal aanbod aan plug-ins en thema's.
          Dankzij de actieve gemeenschap die werkt aan Wordpress is er voor ieder probleem wel een oplossing en kan jij aan de slag met jouw ideale website.
        </TextGeneral>
      </TextImage>
      <TextImage
        image={FixWP}
        altImage="Wordpress"
        title="Waarom Wordpress"
        reverse={true}
      >
        <TextGeneral>
          Wordpress wordt door bijna de helft van alle webpagina's gebruikt om websites mee te maken, en dit heeft een goede reden.
          Wordpress is heel gemakkelijk in gebruik te nemen en er is een gigantisch aantal aanbod aan plug-ins en thema's.
          Dankzij de actieve gemeenschap die werkt aan Wordpress is er voor ieder probleem wel een oplossing en kan jij aan de slag met jouw ideale website.
        </TextGeneral>
      </TextImage>
    </Fragment>
  )
}

export default WhyWordpress