import { Component } from 'react'
import { PageAnchor } from '../../styled/container'
import { TextHeader } from '../../styled/text'
import { TextImageContainer, TextContainer, ImageContainer, TextWrapper, Image } from './textImage.style'

/**
 * @param {String} title
 * @param {String} image path to image
 * @param {String} altImage 
 * @param {Boolean} reverse change image to left side
 */
class TextImage extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => {
    const { image, altImage, children, title, reverse, id } = this.props

    return (
      <TextImageContainer style={{ flexDirection: reverse ? "row-reverse" : "row" }}>
        {
          typeof id === "string" && id.length > 0 ?
            <PageAnchor id={id} />
          :
            ""
        }
        <TextContainer>
          <TextHeader>{ title }</TextHeader>

          <TextWrapper> { children } </TextWrapper>
        </TextContainer>

        <ImageContainer>
          {
            typeof image === "string" ?
              <Image src={image} alt={altImage} width="150" height="150" />
            :
              image
          }
        </ImageContainer>
      </TextImageContainer>
    )
  }
}

export default TextImage