import { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import { InlineLink, PageTitle, SubHeader } from '../../styled/text'
import MoreInfo from '../reusables/moreInfo'
import TextImage from '../reusables/textImage'

import Introduction from './intro'
import WhyWordpress from './why-wordpress'

import Storage from '../../assets/img/server/storage.svg'
import Dots from '../../assets/img/thin-outline/dots-filled.svg'
import Backup from '../../assets/img/thin-outline/backup.svg'
import SecureServer from '../../assets/img/server/secure-server.svg'
import FastServer from '../../assets/img/server/fast-server.svg'
import { FeatureSubline, FeatureTagline, FeatureText } from '../../styled/features'
import Package from '../reusables/package'

class Wordpress extends Component {
  constructor() {
    super()
    this.state = {

    }

    this.wordpressSubjects = [
      {
        url: "/wordpress/why-wordpress",
        text: "Waarom Wordpress",
        component: WhyWordpress
      },
      {
        url: "/wordpress/intro",
        text: "Een introductie tot Wordpress",
        component: Introduction
      },
      {
        url: "/wordpress/mail",
        text: "Mailen via Wordpress",
        component: <div></div>
      },
      {
        url: "/wordpress/security",
        text: "Extra beveiliging voor Wordpress",
        component: <div></div>
      },
      {
        url: "/wordpress/developers",
        text: "Gemak voor ontwikkelaars",
        component: <div></div>
      }
    ]
  }

  render = () => (
    <Fragment>
      <Switch>
        {/* <Route path="/wordpress/" component={} /> */}
        {
          this.wordpressSubjects.map(v => typeof v.component === "function" ? <Route key={v.url} path={v.url} component={v.component} /> : <Route key={v.url} path={v.url}> {v.component} </Route>)
        }
        <Route path="/">
          <PageTitle> Wordpress </PageTitle>

          <SubHeader> Alle voordelen van onze <InlineLink to="/hosting">hosting</InlineLink> met optimalisatie voor Wordpress </SubHeader>

          <Package />

          <TextImage
            image={SecureServer}
            altImage="Wordpress"
            title="Beveiliging"
            id="security"
          >
            <Fragment>
              <FeatureTagline> De beste beveiliging voor jouw site </FeatureTagline>

              <FeatureSubline> Extra beveiliging boven op de <InlineLink to="/hosting#security">standaard hosting beveiliging</InlineLink> </FeatureSubline>
              <FeatureSubline> Sterke beveiliging met inbegrepen SSL certificaat  </FeatureSubline>
              <FeatureSubline> Beveiliging op de login pagina </FeatureSubline>

              <FeatureText>
                Lees meer over onze beveiliging voor Wordpress
              </FeatureText>
            </Fragment>
          </TextImage>

          <TextImage
            image={FastServer}
            altImage="Wordpress"
            title="Snelheid"
            id="speed"
          >
            <Fragment>
              <FeatureTagline> Maak gebruik van de laatste technieken </FeatureTagline>

              <FeatureSubline> Laadtijd eerste document tot wel 1000% sneller dan andere Wordpress hosters </FeatureSubline>
              <FeatureSubline> 1Gbps up/down link </FeatureSubline>
              <FeatureSubline> SSD only </FeatureSubline>
              <FeatureSubline> Cache voor afbeeldingen </FeatureSubline>

              <FeatureText> Bekijk hier waarom een snelle website belangrijk is </FeatureText>
            </Fragment>
          </TextImage>

          <TextImage
            image={
              <div style={{ position: "relative", height: "100%", width: "auto" }}>
                <img src={Storage} alt="Backup storage" style={{
                  width: "40%",
                  position: "absolute",
                  left: "20%",
                  filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .1))",
                  top: "28%",
                  transform: "translate(-50%, -50%)"
                }} />
                <img src={Dots} alt="Dots" style={{
                  width: "18%",
                  position: "absolute",
                  left: "50%",
                  top: "28%",
                  transform: "translate(-50%, -50%)"
                }} />
                <img src={Storage} alt="Backup storage" style={{
                  width: "40%",
                  position: "absolute",
                  left: "80%",
                  filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .1))",
                  top: "28%",
                  transform: "translate(-50%, -50%)"
                }} />
                <img src={Backup} alt="Backup" style={{
                  width: "20%",
                  position: "absolute",
                  left: "79.5%",
                  top: "28%",
                  transform: "translate(-50%, -50%)"
                }} />
                <img src={Storage} alt="Backup storage" style={{
                  width: "35%",
                  opacity: 0
                }} />
              </div>
            }
            title="Back-ups"
            id="backups"
          >
            <Fragment>
              <FeatureTagline> Nooit meer bang dat je gegevens kwijt zijn </FeatureTagline>

              <FeatureSubline> Gratis 10 back-up revisies </FeatureSubline>
              <FeatureSubline> Één gratis handmatige back-up </FeatureSubline>
              <FeatureSubline> Kies welke bestanden je wilt herstellen </FeatureSubline>
              <FeatureSubline> Automatische back-ups met een instelbaar schema </FeatureSubline>

              <FeatureText>
                Bekijk onze back-up oplossing
              </FeatureText>
            </Fragment>
          </TextImage>
        </Route>
      </Switch>

      <MoreInfo title="Meer informatie over Wordpress" subjects={this.wordpressSubjects} />
    </Fragment>
  )
}

export default Wordpress