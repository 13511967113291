import { Component, Fragment } from 'react'
import { ContactPageTitle, ContactContainer, ContactLink, ContactHeader, ContactImageText, ContactInfoWrapper, DisclaimerText } from './start.styled'

import At from '../../assets/img/thin-outline/at.svg'
import Phone from '../../assets/img/thin-outline/phone.svg'
import Suitcase from '../../assets/img/thin-outline/suitcase.svg'
import Checklist from '../../assets/img/thin-outline/checklist.svg'

class Start extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <Fragment>
      <ContactPageTitle> Start bij Web-Fuse </ContactPageTitle>

      <DisclaimerText>
        Omdat wij een startend bedrijf zijn wordt er nog gewerkt aan een automatisch betaal en registratie systeem. <br/>
        Om deze reden vragen wij u om contact op te nemen telefonisch of per mail. <br/>
        Natuurlijk staan wij altijd open voor suggesties en goede ideeën om zo door te groeien tot een van de meest gebruiksvriendelijke en veelzijdige hosting platformen.
      </DisclaimerText>

      <ContactContainer>
        <ContactHeader> Wij zijn bereikbaar via </ContactHeader>

        <ContactLink href="mailto:info@web-fuse.nl" target="_blank" rel="noopener noreferrer">
          <ContactImageText>
            <img src={At} alt="mail" width="20" height="20" />
            E-mail
          </ContactImageText>
          <p> info@web-fuse.nl </p>
        </ContactLink>

        <ContactLink href="tel:31681773998">
          <ContactImageText>
            <img src={Phone} alt="phone" width="20" height="20" />
            Telefoon
          </ContactImageText>
          <p> +31 6 8177 3998 </p>
        </ContactLink>

        <ContactInfoWrapper>
          <ContactImageText>
            <img src={Suitcase} alt="suitcase" width="20" height="20" />
            KvK nummer
          </ContactImageText>
          <p> 81672802 </p>
        </ContactInfoWrapper>
      </ContactContainer>

      <ContactContainer>
        <ContactHeader> Juridische informatie </ContactHeader>

        <ContactLink href="/assets/doc/Algemene_Voorwaarden_Webfuse.pdf" target="_blank" rel="noopener noreferrer">
          <ContactImageText>
            <img src={Checklist} alt="checklist" width="20" height="20" />
            Algemene voorwaarden
          </ContactImageText>
        </ContactLink>
      </ContactContainer>

    </Fragment>
  )
}

export default Start
