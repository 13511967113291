import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start 2;
  flex-wrap: wrap;
  padding: 3rem 10vw 3rem 10vw;
  color: white;
  background-color: var(--primary-color);
  gap: 2rem;
`

export const FooterColumn = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: .2rem 1rem;
  height: min-content;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

export const FooterHeader = styled.h4`
  color: white;
  grid-column: 1/3;
  margin-bottom: .5rem;
  text-transform: uppercase;
`

export const FooterLink = styled(Link)`
  color: white;
  transition: color .3s;

  :hover {
    color: var(--off-white);
  }

  @media only screen and (max-width: 600px) {
    grid-column: 1/3;
  }
`

export const FooterLinkS = styled(FooterLink)`
  grid-column: 1/3;
`

export const FooterSubject = styled.p`
  @media only screen and (max-width: 600px) {
    grid-column: 1/3;
    margin-bottom: -.2rem;
    margin-top: .5rem;

    :nth-child(2) {
      margin-top: unset;
    }
  }
`

export const FooterA = styled.a`
  color: white;
  transition: color .3s;

  :hover {
    color: var(--off-white);
  }
`

export const FooterLogoContainer = styled.div`
  margin: -23px 0 -23px auto;
  /* filter: drop-shadow(5px -3px black); */

  @media only screen and (max-width: 1200px) {
    margin: -23px 0;
  }
`