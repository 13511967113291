import { Component } from 'react'
import { FooterA, FooterColumn, FooterContainer, FooterHeader, FooterLinkS, FooterLogoContainer, FooterSubject } from './footer.style'

class Footer extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <FooterContainer>
      {/* <FooterHeader> Neem contact op! </FooterHeader> */}

      <FooterColumn>
        <FooterHeader> Bedrijfsinformatie </FooterHeader>

        <FooterSubject> E-mail </FooterSubject>
        <FooterA href="mailto:info@web-fuse.nl" target="_blank" rel="noopener noreferrer"> info@web-fuse.nl </FooterA>

        <FooterSubject> Telefoon </FooterSubject>
        <FooterA href="tel:31681773998"> +31 6 8177 3998 </FooterA>

        <FooterSubject> KvK </FooterSubject>
        <p> 81672802 </p>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader> Wordpress </FooterHeader>

        <FooterLinkS to="/wordpress/intro"> Introductie </FooterLinkS>
        <FooterLinkS to="/wordpress/develop"> Ontwerpen </FooterLinkS>
        <FooterLinkS to="/wordpress/security"> Beveiliging </FooterLinkS>
        <FooterLinkS to="/wordpress/mail"> Mailen </FooterLinkS>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader> Hosting </FooterHeader>

        <FooterLinkS to="/hosting/intro"> Introductie </FooterLinkS>
        <FooterLinkS to="/hosting/security"> Beveiliging </FooterLinkS>
        <FooterLinkS to="/hosting/mail"> Mailen </FooterLinkS>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader> Mail </FooterHeader>

        <FooterLinkS to="/wordpress/accounts"> Accounts </FooterLinkS>
        <FooterLinkS to="/wordpress/forward"> Doorsturen </FooterLinkS>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader> Juridisch </FooterHeader>

        <FooterA href="/assets/doc/Algemene_Voorwaarden_Webfuse.pdf" target="_blank" rel="noopener noreferrer"> Algemene Voorwaarden </FooterA>
        <FooterLinkS to="/cookies"> Cookies </FooterLinkS>
      </FooterColumn>

      <FooterLogoContainer>
        <img src="/logo-fill-wht.png" alt="web-fuse logo" width="125" height="125" />
      </FooterLogoContainer>
    </FooterContainer>
  )
}

export default Footer