import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'

import LinkIcon from '../assets/img/other/link.svg'
import TickSquare from '../assets/img/other/tick-square.svg'
import HeaderBG from '../assets/img/other/header-bg.jpg'

export const PageTitle = styled.h1`
  margin-bottom: 2rem;
  margin-top: -8rem;
  text-align: center;
  text-transform: uppercase;
  padding: 10rem 0 4rem 0;
  background: url(${HeaderBG});
  color: white;
  font-size: 2.75rem;
`

export const SubHeader = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  font-family: 'Play', sans-serif;
  font-weight: 700;
`

export const TextHeader = styled.h3`
  margin: 1em -.12em;
  font-size: 1.5em;
  font-family: 'Play', sans-serif;
  font-weight: 700;
  max-width: max-content;
  position: relative;

  ::before { content: '[ '; }
  ::after { content: ' ]'; }

  /* unfortunately this is still beta */
  /* @container (height > 1.5em) {
    ::after { content: '' }
    ::before { content: '' }
  } */
`

export const TextGeneral = styled.p`
  margin: 0 1rem 1em 1rem;
  max-width: 60rem;
`
export const InlineLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: inherit;
  /* margin-right: .7em; */

  :hover {
    text-decoration: underline;

    // change icon on hover link
    ::after {
      background: url(${TickSquare});
      background-size: .6em .6em;
      background-repeat: no-repeat;
      /* width: .65em; */
      /* height: .65em; */
      /* right: -.7em; */
      /* top: .5em; */
    }
  }

  ::after {
    content: '';
    background: url(${LinkIcon});
    /* position: absolute; */
    background-size: .55em .55em;
    background-repeat: no-repeat;
    width: .6em;
    height: .6em;
    /* right: -.65em; */
    /* bottom: .25em; */
    display: inline-block;
    margin-left: min(.2em, 6px);
  }
`

export const Button = styled.div`
  color: white;
  background-color: var(--secondary-color);
  box-shadow: 0 2px black;
  padding: .5rem;
  text-align: center;
  border-radius: .25rem;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  font-size: 1.3rem;

  :hover {
    box-shadow: 0 3px black;
  }
`

export const Button90 = styled(Button)`
  /* width: 90%; */
  flex-grow: 1;
`