import { Component } from 'react'
import { PackageA, PackageContainerSimple, PackageGroupContainer, PackageHeader, PackageImg, PackageList, PackageListFlex, PackageListItem } from './package.styled'
import { Button90 } from '../../styled/text'

import Mail from '../../assets/img/thin-outline/mail.svg'
import Server from '../../assets/img/thin-outline/server.svg'
import Wordpress from '../../assets/img/other/wordpress.svg'

class PackageGroup extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <PackageGroupContainer>
      <PackageContainerSimple>
        <PackageImg src={Server} alt="hosting server" width="100" height="100" />
        <PackageHeader> Web hosting </PackageHeader>
        <PackageList>
          <PackageListFlex>
            <PackageListItem> Opslagruimte </PackageListItem>
            <PackageListItem> 1GB </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Dataverkeer </PackageListItem>
            <PackageListItem> Onbeperkt </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Databases </PackageListItem>
            <PackageListItem> Onbeperkt </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> SSL </PackageListItem>
            <PackageListItem> Inbegrepen </PackageListItem>
          </PackageListFlex>
        </PackageList>

        <PackageA href="/start">
          <Button90> Start nu </Button90>
        </PackageA>
      </PackageContainerSimple>

      <PackageContainerSimple>
        <PackageImg src={Wordpress} alt="hosting wordpress" width="100" height="100" />
        <PackageHeader> Wordpress </PackageHeader>
        <PackageList>
          <PackageListFlex>
            <PackageListItem> Opslagruimte </PackageListItem>
            <PackageListItem> 1GB </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Dataverkeer </PackageListItem>
            <PackageListItem> Onbeperkt </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Databases </PackageListItem>
            <PackageListItem> Onbeperkt </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> SSL </PackageListItem>
            <PackageListItem> Inbegrepen </PackageListItem>
          </PackageListFlex>
        </PackageList>

        <PackageA href="/start">
          <Button90> Start nu </Button90>
        </PackageA>
      </PackageContainerSimple>

      <PackageContainerSimple>
        <PackageImg src={Mail} alt="mail server" width="100" height="100" />
        <PackageHeader> E-mail only (Basis pakket) </PackageHeader>
        <PackageList>
          <PackageListFlex>
            <PackageListItem> Mailboxen </PackageListItem>
            <PackageListItem> 10 </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Aantal berichten </PackageListItem>
            <PackageListItem> 1000 p/m </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Opslag </PackageListItem>
            <PackageListItem> 5GB </PackageListItem>
          </PackageListFlex>
          <PackageListFlex>
            <PackageListItem> Anti-spam </PackageListItem>
            <PackageListItem> SE en Microsoft </PackageListItem>
          </PackageListFlex>
        </PackageList>

        <PackageA href="/start">
          <Button90> Start nu </Button90>
        </PackageA>
      </PackageContainerSimple>
    </PackageGroupContainer>
  )
}

export default PackageGroup