import styled from 'styled-components'
import { PageTitle } from '../../styled/text'

import ContactSeamlessHeader from '../../assets/img/other/contact-seamless-header.svg'

export const ContactPageTitle = styled(PageTitle)`
  background: url(${ContactSeamlessHeader});
`

export const ContactHeader = styled.h3`
  margin-bottom: .5rem;
`

export const ContactContainer = styled.div`
  margin: 6rem 20vw;
  display: flex;
  gap: .75rem;
  flex-direction: column;
  font-size: 1.1em;

  @media only screen and (max-width: 800px) {
    margin: 6rem .5rem;
  }
`

export const ContactInfoWrapper = styled.div`
`

export const ContactLink = styled.a`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: .2rem;
  flex-direction: column;
  width: max-content;
  transition: transform .2s ease-in-out;

  :hover {
    transform: translateY(-.15em);
  }
`

export const ContactImageText = styled.div`
  display: flex;
  align-items: center;
  gap: .2rem;
  width: max-content;
`