import { Component } from 'react'
import { PackageContainer, PackageList, PackageListFlex, PackageListItem } from './package.styled'
import { Button } from '../../styled/text'

class Package extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <PackageContainer>
      <h3> Één pakket dat met jou mee groeit </h3>

      <PackageList>
        <PackageListFlex>
          <PackageListItem> Opslagruimte </PackageListItem>
          <PackageListItem> 1GB </PackageListItem>
        </PackageListFlex>
        <PackageListFlex>
          <PackageListItem> Dataverkeer </PackageListItem>
          <PackageListItem> Onbeperkt </PackageListItem>
        </PackageListFlex>
        <PackageListFlex>
          <PackageListItem> Databases </PackageListItem>
          <PackageListItem> Onbeperkt </PackageListItem>
        </PackageListFlex>
        <PackageListFlex>
          <PackageListItem> SSL </PackageListItem>
          <PackageListItem> Inbegrepen </PackageListItem>
        </PackageListFlex>
      </PackageList>

      <a href="/start">
        <Button> Start nu </Button>
      </a>
    </PackageContainer>
  )
}

export default Package