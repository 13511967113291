import styled from 'styled-components'
import { FadeUp } from '../../styled/keyframes'

import HeaderBG from '../../assets/img/other/header-bg.jpg'

export const HeaderContainer = styled.div`
  background: linear-gradient(-90deg, rgba(0,0,0,.1), rgba(0,0,0,.3));
  margin: -4rem 0 3rem 0;
  padding: 11rem 0 7.5rem 0;
  position: relative;

  ::after {
    content: '';
    background: url(${HeaderBG});
    background-position: 50% 25%;
    background-size: 110%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    filter: contrast(115%) hue-rotate(21deg);
  }

  ::before {
    content: '';
    background-color: #01040d;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  @media only screen and (min-width: 1201px) {
    ::after {
      background-position: 50% 40%;
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 8rem .5rem 5rem .5rem;

    ::after {
      background-position: 50% 100%;
    }
  }
  /* @media only screen and (max-width: 650px) {
    padding: 7rem .5rem 3rem .5rem;
  } */
  @media only screen and (max-width: 525px) {
    padding: 6rem .5rem 4rem .5rem;
  }
`

export const LandingHeaderText = styled.h1`
  font-family: 'JetBrains Mono', monospace;
  font-size: 2.75rem;
  letter-spacing: -2px;
  text-align: left;
  position: inherit;
  z-index: 5;
  max-width: 11em;
  margin-left: 20%;
  color: white;
  animation: ${FadeUp} .5s ease-in-out;

  @media only screen and (max-width: 800px) {
    margin-left: 10%;
  }
  
  @media only screen and (max-width: 650px) {
    font-size: 2.25rem;
  }
  
  @media only screen and (max-width: 560px) {
    font-size: 1.75rem;
  }
`

export const ValuePropContainer = styled.div`
  padding: 8rem 20% 4rem 20%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -3rem;
  gap: 2rem;

  /* ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    filter: opacity(.3);
    z-index: -1;
  } */

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 4rem 20%;
  }

  @media only screen and (max-width: 800px) {
    padding: 4rem .5rem;
  }
`

export const ValuePropTextWrapper = styled.div`
  font-size: 1.25em;
  max-width: 600px;
  margin-bottom: auto;
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 600px) {
    font-size: 1.1em;
  }
`

export const ValuePropHeader = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.25em;
  color: var(--secondary-color);
  font-family: 'JetBrains Mono', monospace;
  letter-spacing: -1px;
  text-transform: uppercase;
`
export const ValuePropText = styled.p`
  /* margin-left: 1.25em; */
`

export const ValuePropImg = styled.img`
  height: min(20vw, 250px);
  width: unset;

  @media only screen and (max-width: 1200px) {
    height: calc(200px + 10vw);
  }

  @media only screen and (max-width: 475px) {
    margin-bottom: -1rem;
  }
`