import { Component } from 'react'
import { InfoImageContainer, InfoLink, InfoLinkWrapper, MoreInfoContainer, InfoTextContainer } from './moreInfo.style'

import Blog from '../../assets/img/other/blog.svg'
import { TextHeader } from '../../styled/text'

/**
 * @param {String} title
 * @param {Object} subjects array of subjects that consists of objects with the items text and url
 * @param {String} image optional image path
 */
class MoreInfo extends Component {
  constructor() {
    super()
    this.state = {
      shownLinks: 0
    }

  }

  componentDidMount = () => {
    let shownLinks = 0
    this.props.subjects.forEach(v => { if (window.location.pathname.indexOf(v.url) !== 0) shownLinks++ })
    this.setState({ shownLinks })
  }

  renderLinks = () => this.props.subjects.map(v => {
    if (window.location.pathname.indexOf(v.url) !== 0) return (
      <InfoLink key={v.url}>
        - <InfoLinkWrapper to={v.url}>{ v.text }</InfoLinkWrapper>
      </InfoLink>
    )

    return ""
  })

  render = () => {
    const { title } = this.props

    if (this.state.shownLinks > 0) return (
      <MoreInfoContainer>
        <InfoTextContainer>
          <TextHeader style={{ maxWidth: "max-content" }}>{ title }</TextHeader>

          { this.renderLinks() }
        </InfoTextContainer>

        <InfoImageContainer>
          <img src={Blog} alt="checklist" width="160" height="160" style={{ width: "100%", height: "100%", filter: "hue-rotate(-22deg)" }} />
        </InfoImageContainer>
      </MoreInfoContainer>
    )

    return ""
  }
}

export default MoreInfo