import styled from 'styled-components'

export const HeaderStyle = styled.div`
  height: 4rem;

  user-select: none;
  text-align: center;
  z-index: 999;
  width: 100%;
  position: sticky;
  top: 0;
  transition: background-color .4s;
  overflow-x: clip;

  /* @media screen and (max-width: 1050px) {
    ::before,
    ::after {
      opacity: 0;
    }
    background-color: black;
  } */

  /* @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: .5rem auto auto .5rem;
  } */

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 10% 48px auto 10%;
  }

  /* ::before,
  ::after {
    content: '';
    width: calc(100vw + 5rem);
    height: calc(28rem - 3vw);
    background-color: white;
    position: absolute;
    box-shadow: 0 .1rem .4rem .3rem rgba(0,0,0,.1);
  }
  
  ::after {
    z-index: 1;
    transform:
      rotateZ(8deg)
      translate(-6rem, -21rem);
    border-radius: 0 0 5rem 0;
  }

  ::before {
    z-index: 0;
    transform:
      rotateZ(-2deg)
      translateY(-22rem);
  } */
`


export const NavLogo = styled.img`
  margin-top: calc(calc(4rem - 48px) / 2);
  position: initial;
  z-index: 999;

  @media only screen and (max-width: 800px) {
    margin: .8em auto auto auto;
  }
`

export const NavToggleInput = styled.input`
  display: block;
  background-color: white;
  height: .2em;
  width: 2em;
  border-radius: 1em;
  position: relative;
  appearance: none;
  outline: unset;
  transition: transform .4s ease-in-out, background-color .5s;

  &::after,
  &::before {
    display: block;
    background-color: white;
    height: .2em;
    width: 2em;
    border-radius: 1em;
    position: relative;
    content: '';
    position: absolute;
    transition: transform .4s ease-in-out;
  }

  &::after {
    transform: translateY(-.6em)
  }

  &::before {
    transform: translateY(.6em)
  }

  &:checked {
    transform: translateX(-2em);
    background: transparent;
  }

  &:checked::after {
    transform: rotateZ(45deg) translate(1.4em, -1.4em);
  }

  &:checked::before {
    transform: rotateZ(-45deg) translate(1.4em, 1.4em);
  }
`

export const NavToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 800px) {
    display: none;
  }
`

export const NavStyle = styled.div`
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: rgba(0,0,0,.87);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform .4s ease-in-out;
  z-index: 999;

  @media screen and (min-width: 800px) {
    /* the following lines are not from my video, but add Edge support */
    position: relative;
    text-align: left;
    transition: none;
    transform: scale(1,1);
    background: none;
    top: initial;
    left: initial;
    /* end Edge support stuff */

    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const HeaderItems = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
  }
`

export const HeaderTextBox = styled.div`
  position: relative;
  margin-bottom: 1em;
  margin-left: 1em;
  text-decoration: none;
  margin-top: 9px;

  @media screen and (min-width: 800px) {
    margin-left: unset;
    margin-bottom: unset;
    padding: 0 .75em;
  }
`

export const HeaderText = styled.h2`
  font-size: 1em;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  flex: 1 1 15em;
  cursor: pointer;
  transition:
    transform .3s ease-in-out,
    opacity 150ms ease-in-out;
  color: white;

  &::after {
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 2px;
    width: 0px;
    background-color: white;
    display: block;
    content:'';
    transition: width .225s cubic-bezier(0.8, 0, 0.4, 1);
  }

  &:hover::after{
    width: 2.25em;
  }

  width: max-content;
  text-transform: uppercase;
  opacity: 0;

  @media screen and (min-width: 800px) {
    opacity: 1;
    position: relative;
  }
`

export const NavMenu = styled.div`
  height: 100%;
`
export const NavMenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  margin-bottom: 1rem;
`

export const NavMenuColumn = styled.div`
  @media only screen and (max-width: 400px) {
    width: 80vw;
  }
`

export const NavMenuSubject = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.25em;
  color: var(--secondary-color);
  font-family: 'JetBrains Mono', monospace;
  letter-spacing: -1px;
  font-weight: 600;
`

export const NavMenuText = styled(HeaderText)`
  text-transform: none;
  padding-bottom: 4px;
  opacity: 1;
  text-align: left;
  font-size: .9em;

  ::after {
    bottom: 2px;
  }
`

export const LoginButtonStyle = styled.div`
  padding: .5em 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid white;
  transition: border .2s, background-color .2s;
  margin: 0 .5rem;
  
  :hover {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  clip-path: inset(10% 10% 10% 10% round 20%, 20%);

  @media screen and (min-width: 800px) {
    margin: 0 0 0 .75rem;
    /* margin-left: unset;
    margin-bottom: unset; */
  }
`

export const LoginButtonText = styled.h2`
  font-size: 1em;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  cursor: pointer;
  transition:
    transform .3s ease-in-out,
    opacity 150ms ease-in-out;
  color: white;

  width: max-content;
  text-transform: uppercase;
  opacity: 0;

  @media screen and (min-width: 800px) {
    opacity: 1;
    position: relative;
  }
`