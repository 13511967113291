import styled from 'styled-components'

export const MainContent = styled.div`
  max-width: 100vw;
`

export const PageAnchor = styled.div`
  position: absolute;
  top: -5rem;
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
  flex
`