import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TextContainer } from './textImage.style'

import LinkIcon from '../../assets/img/other/link.svg'
import TickSquare from '../../assets/img/other/tick-square.svg'

export const MoreInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem 4rem;
  padding: 1rem 20vw 4rem 20vw;
  background: linear-gradient(#a5a5a555, transparent);

  @media only screen and (max-width: 1200px) {
    padding: 1rem 10vw 4rem 10vw;
  }

  @media only screen and (max-width: 800px) {
    padding: 1rem .5rem 4rem .5rem;
    justify-content: center;
  }
`

export const InfoLinkWrapper = styled(Link)`
  color: var(--link-color);
  display: inline-block;

  :hover {
    text-decoration: underline;

    // change icon on hover link
    ::after {
      background: url(${TickSquare});
      background-size: .6em .6em;
      background-repeat: no-repeat;
    }
  }

  ::after {
    content: '';
    background: url(${LinkIcon});
    background-size: .55em .55em;
    background-repeat: no-repeat;
    width: .6em;
    height: .6em;
    display: inline-block;
    margin-left: min(.2em, 6px);
  }
`

export const InfoLink = styled.p`
  margin-left: 1rem;
  color: var(--link-color);
  font-weight: 700;
`

export const InfoTextContainer = styled(TextContainer)`
  display: flex;
  flex-direction: column;
  gap: .25rem 0;
  width: max-content;

  @media only screen and (max-width: 800px) {
    max-width: unset;
  }
`

export const InfoImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max(200px, min(20vw, 325px));
  height: max(200px, min(20vw, 325px));
  margin-bottom: -3rem;

  @media only screen and (max-width: 800px) {
    width: 50vw;
    height: unset;
  }
`