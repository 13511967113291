import styled from 'styled-components'

import TickGreen from '../assets/img/other/tick-green.svg'

export const FeatureTagline = styled.h4`
  margin-left: 3.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Play', sans-serif;
  font-weight: 700;
`

export const FeatureSubline = styled.p`
  margin-left: 3.5rem;
  position: relative;
  margin-bottom: .5rem;

  :before {
    content: '';
    position: absolute;
    transform: translate(-1.5em, 25%);
    background-image: url(${TickGreen});
    height: 1em;
    width: 1em;
  }
`

export const FeatureText = styled.p`
  margin-top: 1.5rem;
  margin-left: 1.5rem;
`