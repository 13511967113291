import styled, { keyframes } from "styled-components"

import Rocket from '../../assets/img/thin-outline/rocket.svg'

const hover = keyframes`
  0% {
    transform: translateX(-50%) translateY(0);
  }
  25% {
    transform: translateX(-46%) translateY(-5%);
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 3px 3px 2px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
`

export const PackageContainer = styled.div`
  background-color: white;
  padding: 2rem;
  padding-top: calc(2rem + 150px);
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .5rem;
  width: max-content;
  position: relative;
  flex-basis: 350px;
  margin: auto;

  :hover {
    ::after {
      /* animation: ${hover} 2.5s ease-in-out; */
      transform: translateX(-46%) translateY(-5%);
      filter: drop-shadow(rgba(0, 0, 0, 0.5) -2px 5px 2px);
    }
  }

  ::after {
    content: url(${Rocket});
    position: absolute;
    top: 15px;
    /* left: 67%; */
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
    transition:
      transform .4s ease-in-out,
      filter .3s ease-in-out;
  }
`

export const PackageGroupContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 2rem 20vw 8rem 20vw;
  flex-wrap: wrap;

  @media only screen and (max-width: 1600px) {
    margin: 2rem 10vw 8rem 10vw;
  }

  @media only screen and (max-width: 1200px) {
    margin: 2rem .5rem;
    justify-content: center;
  }
`

export const PackageContainerSimple = styled.div`
  background-color: white;
  padding: 2rem;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .5rem;
  position: relative;
  flex-basis: 300px;
  flex-grow: 1;
  transition:
    transform .2s ease-in-out,
    box-shadow .2s ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  :hover {
    transform: translateY(-.5rem);
    box-shadow: 0 .5rem .4rem -.15rem rgba(0,0,0,.3);
  }

  @media only screen and (max-width: 1200px) {
    flex-grow: 0;
    padding: 2rem 4rem;
    flex-basis: 350px;
    margin: 0 10vw;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem;
    margin: unset;
    flex-basis: 350px;
  }

  /* @media only screen and  */
`

export const PackageList = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  margin: 1rem 0;
  width: 100%;
`

export const PackageListFlex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 0;
  border-bottom: 1px solid rgba(0,0,0,.25);
  
  :last-child {
    border-bottom: unset;
  }
`

export const PackageListItem = styled.p`
  color: rgba(0,0,0,.9);
  font-weight: 500;

  :nth-child(1) {
    margin-left: .3rem;
  }

  :nth-child(2) {
    margin-right: .3rem;
  }
`

export const PackageHeader = styled.h3`
  text-align: center;
  margin-top: 1em;
`

export const PackageImg = styled.img`
  width: 40%;
  height: unset;
  /* margin-left: 50%; */
  /* transform: translateX(-50%); */
  transition:
    transform .4s ease-in-out,
    filter .3s ease-in-out;
`

export const PackageA = styled.a`
  width: 100%;
`