import { Component, Fragment, createRef } from 'react'
import styled from 'styled-components'

import CaretDown from '../../assets/img/thin-outline/caret-down-wht.svg'

const DropdownContainer = styled.div`
  font-family: 'Noto Sans JP', sans-serif;
  text-transform: uppercase;
  opacity: 0;
  margin-left: 1em;
  height: 100%;
  color: white;

  @media screen and (min-width: 801px) {
    /* padding: calc(calc(4rem - 1.5rem) / 2) .75em 0 .75em; */
    opacity: 1;
    margin-left: unset;
  }
`

const DropdownHeader = styled.h2`
  font-size: 1em;
  cursor: pointer;
  text-transform: uppercase;
  text-align: left;
  display: block;

  @media only screen and (min-width: 801px) {
    padding: calc(calc(4rem - 1.5rem) / 2) .75em;
  }
`

const DropdownWrapper = styled.div`
  position: absolute;
  top: 4rem;
  left: calc(-10vw - 48px);
  width: 100vw;
  z-index: 1;
  cursor: default;
  text-transform: none;

  @media only screen and (max-width: 800px) {
    /* left: -1rem;
    top: 2.5rem; */
    position: initial;
    margin-left: -1em;
  }
`

const DropdownContent = styled.div`
  background-color: black;
  min-height: 18rem;
  padding: 2rem 20vw;
  transition: opacity 1s;
  text-align: left;
  border-top: 2px solid rgba(100,100,100,.35);
  border-bottom: 2px solid rgba(100,100,100,.35);

  :hover {
    display: block;
    opacity: 1;
  }

  @media only screen and (max-width: 800px) {
    left: 0;
    top: 3rem;
    padding: 1rem;
    min-height: unset;
  }
`

const delay = t => new Promise(resolve => setTimeout(resolve, t))

/**
 * props: show, activeColor, inactiveColor, title, image
 */
class MenuDropdown extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      waitAnimation: true,
      unfoldHeight: 0,
      unfoldedByClick: false
    }

    this.lastShowProp = false
    this.unfoldRef = createRef()
    this.doToggleShow = this.doToggleShow.bind(this)
  }

  componentDidMount = () => {
    if (this.props.show) this.doToggleShow(false, true)
    this.lastShowProp = this.props.show

    if (this.props.setToggleFunc) this.props.setToggleFunc(this.doToggleShow)
  }

  componentDidUpdate = () => {
    if (this.props.show !== this.lastShowProp && this.props.show !== this.state.show) {
      this.lastShowProp = this.props.show
      this.doToggleShow()
    }
  }

  /**
   * handles to show and hide animation
   * @param {Boolean} doShow should the dropdown be shown
   */
  doToggleShow = (byClick, doShow) => {
    const { show, unfoldedByClick } = this.state

    if (
      (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      ) && !byClick) return;

    if (byClick && show && !unfoldedByClick && doShow !== false) return this.setState({ unfoldedByClick: true })
    if (byClick && !show && !unfoldedByClick && doShow !== false) this.setState({ unfoldedByClick: true })
    if (byClick && show && unfoldedByClick) this.setState({ unfoldedByClick: false })
    if (show && unfoldedByClick && !byClick) return;
    if (show || doShow === false) { // hide
      this.setState({waitAnimation: true})
      return delay(200).then(() => this.setState({show: false}))
    } else { // show
      this.setState({show: true, waitAnimation: false})
      this.calcHeight()
    }
  }

  calcHeight = () => this.setState({unfoldHeight: this.unfoldRef.current.offsetHeight + "px"})

  renderDropdown = () => { if (this.state.show) return this.props.children }

  render() {
    return (
      <Fragment>
        <DropdownContainer style={this.props.style} onMouseEnter={() => this.doToggleShow(false, true)} onMouseLeave={() => this.doToggleShow(false, false)}>
          <DropdownHeader onClick={() => this.doToggleShow(true)}>
            {this.props.title}
            <img src={CaretDown} style={{ margin: "0 -.5em -.4em .1em", transform: this.state.waitAnimation ? "rotate(0deg)" : "rotate(-180deg)" }} alt="dropdown" width="24" height="24" />
          </DropdownHeader>

          <DropdownWrapper style={
            Object.assign(
              {
                overflow: "hidden",
                height: this.state.waitAnimation ? "0" : this.state.unfoldHeight,
                transition: "height .2s ease-in-out"
              },
              this.props.childStyle
          )}>
            <DropdownContent ref={this.unfoldRef} style={{
              transform: this.state.waitAnimation ? "translateY(-100%)" : "translateY(0%)",
              transition: "transform .2s ease-in-out"
            }}>
              {this.props.children}
            </DropdownContent>
          </DropdownWrapper>
        </DropdownContainer>
      </Fragment>
    )
  }
}

export default MenuDropdown