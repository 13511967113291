import { Component, Fragment } from 'react'
import { PageTitle } from '../../../styled/text'

class WordpressIntroduction extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <Fragment>
      <PageTitle> Wat is Wordpress </PageTitle>
      <h2>  </h2>
    </Fragment>
  )
}

export default WordpressIntroduction