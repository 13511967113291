import { keyframes } from 'styled-components'

export const FadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(.3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const MoveUp = keyframes`
  0% {
    transform: translateY(.3em);
  }
  100% {
    transform: translateY(0);
  }
`