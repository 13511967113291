import { Component, Fragment } from 'react'
import { HeaderContainer, LandingHeaderText, ValuePropContainer, ValuePropImg, ValuePropHeader, ValuePropText, ValuePropTextWrapper } from './landingpage.styled'

import PackageGroup from '../reusables/package-group'

import FastServer from '../../assets/img/server/fast-server.svg'

class LandingPage extends Component {
  constructor() {
    super()
    this.state = {

    }
  }

  render = () => (
    <Fragment>
      <HeaderContainer>
        {/* <HeaderImage src={DesignTeam} alt="design team" height="224" width="266" /> */}
        <LandingHeaderText>Robuuste en Dynamische Hosting</LandingHeaderText>
      </HeaderContainer>


      {/* <SubHeader> Bijzondere webhosting voor unieke websites </SubHeader> */}

      <ValuePropContainer>

        <ValuePropTextWrapper>
          <ValuePropHeader> Wij leveren snelle en betrouwbare hosting die met je mee groeit </ValuePropHeader>
          <ValuePropText>
            Ons zelfgemaakte platform is gericht op snelheid en gemak zodat jij kan doen wat echt belangrijk is.
            Door gebruik te maken van de laatste technieken voor optimale snelheid en kliks bereik je het grootste publiek.
            En stel jouw publiek gemakkelijk op de hoogte van de laatste nieuwtjes via de standaard geïnstalleerde mailservice.
            Wij bieden één overzichtelijk pakket dat met je mee groeit.
          </ValuePropText>
        </ValuePropTextWrapper>

        {/* <ValuePropImgShadowWrapper>
          <ValuePropImg src={WomanLookingRight} alt="woman looking right" width="190" height="458" />
        </ValuePropImgShadowWrapper>
        <ValuePropImgWrapper>
          <ValuePropImg src={ManLookingLeft} alt="man looking left" width="154" height="460" />
        </ValuePropImgWrapper> */}

        <ValuePropImg src={FastServer} alt="fast server" width="300" height="300" />
      </ValuePropContainer>

      <PackageGroup />

      {/* experimenteer met je website
      prikkel je creativiteit
      voel je je vingertoppen al branden om wat moois te maken? BEGIN DAN NU */}
    </Fragment>
  )
}

export default LandingPage