import { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Index from './components'
import ScrollToTop from './components/functional/scrollToTop'

class Root extends Component {
  constructor() {
    super()
    this.state = {

    }
    this.history = undefined
  }

  render = () => (
    <BrowserRouter history={this.history}>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Index} />
      </Switch>
    </BrowserRouter>
  )
}

export default Root;
